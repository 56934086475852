import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-powerjac',
  templateUrl: './powerjac.component.html',
  styleUrls: ['./powerjac.component.css']
})
export class PowerjacComponent implements OnInit {

  plist;
  constructor() {
    this.imdetin()
  }

  ngOnInit() {
  }

  imdetin() {
    this.plist = [
      { imname: "400", imaddr: "assets/images/amname/400.jpg",mname:'P400SZ' },
      { imname: "400", imaddr: "assets/images/amname/nom.jpg",mname:'P400BZ' },
      { imname: "600", imaddr: "assets/images/amname/nom.jpg",mname:'P600SZ' },
      { imname: "656", imaddr: "assets/images/amname/nom.jpg",mname:'P656SZ' },
    ];

  }
}
