import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toprack',
  templateUrl: './toprack.component.html',
  styleUrls: ['./toprack.component.css']
})
export class ToprackComponent implements OnInit {

  constructor(public rout: Router) { }
 
  ngOnInit() {
  }
  d(){
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      document.getElementById("mdheader").style.fontSize = "30px";
    } else {
      document.getElementById("mdheader").style.fontSize = "90px";
    }
  }
}
 