import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spare',
  templateUrl: './spare.component.html',
  styleUrls: ['./spare.component.css']
})
export class SpareComponent implements OnInit {

  slist;
  constructor() {
    this.imdetin()
  }

  ngOnInit() {
  }

  imdetin() {
    this.slist = [
      { imname: "Frame", imaddr:"assets/images/spares/10.png",mname:""},
      { imname: "Bolt", imaddr:"assets/images/spares/16.png",mname:""},
      { imname: "Nut", imaddr:"assets/images/spares/nom.jpg",mname:""},
      { imname: "Vassar", imaddr:"assets/images/spares/17.png",mname:""},
      { imname: "Ballrus", imaddr:"assets/images/spares/22.png",mname:""},
      { imname: "Jallada Kambi", imaddr:"assets/images/spares/7.png",mname:""},
      { imname: "Nail", imaddr:"assets/images/spares/nom.jpg",mname:""},
      { imname: "Bearing", imaddr:"assets/images/spares/21.png",mname:""},
      { imname: "Cyclinder Bush", imaddr:"assets/images/spares/8.png",mname:""},
      { imname: "Sorugu Bush", imaddr:"assets/images/spares/5.png",mname:""},
      { imname: "Reveet", imaddr:"assets/images/spares/nom.jpg",mname:""},
      { imname: "Spring Vasar", imaddr:"assets/images/spares/9.png",mname:""},
      { imname: "Cottor Pin", imaddr:"assets/images/spares/30.png",mname:""},
      { imname: "Kumil", imaddr:"assets/images/spares/27.png",mname:""},
      { imname: "Screw", imaddr:"assets/images/spares/25.png",mname:""},
      { imname: "Spring", imaddr:"assets/images/spares/31.png",mname:""},
      { imname: "Urulai Chillu", imaddr:"assets/images/spares/24.png",mname:""},
      { imname: "Urulai", imaddr:"assets/images/spares/2.png",mname:""},
      { imname: "Vees Kambi", imaddr:"assets/images/spares/nom.jpg",mname:""},
      { imname: "E Kambi", imaddr:"assets/images/spares/7.png",mname:""},
      { imname: "Pin Kambi", imaddr:"assets/images/spares/nom.jpg",mname:""},
    ];

  }
}





















