import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrontComponent } from 'src/app/front/front.component';
import { PnfComponent } from 'src/app/frames/pnf/pnf.component';
import { HandjacComponent } from 'src/app/handjac/handjac.component';
import { SpareComponent } from 'src/app/spare/spare.component';
import { PowerjacComponent } from 'src/app/powerjac/powerjac.component';


const routes: Routes = [
  { path: "", component: FrontComponent },
  { path: "pagenotfound", component: PnfComponent },
  { path: "handloom", component: HandjacComponent },
  { path: "spare", component: SpareComponent },
  { path: "powerloom", component: PowerjacComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
