import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.css']
})
export class FrontComponent implements OnInit {
  imdet;
  dtype = false;
  constructor() {
    this.imdetin();
    this.dtype = window.innerWidth > window.innerHeight;
  }

  ngOnInit() {

  }

  imdetin() {
    this.imdet = [
      // { imname: "120", imaddr: "assets/images/120.png", cimaddr: "assets/images/amname/120.jpg" },
      { imname: "172", imaddr: "assets/images/172.png", cimaddr: "assets/images/amname/172.jpg" },
      { imname: "240", imaddr: "assets/images/240.png", cimaddr: "assets/images/amname/240.jpg" },
      { imname: "240n", imaddr: "assets/images/240n.png", cimaddr: "assets/images/amname/240n.jpg" },
      { imname: "256", imaddr: "assets/images/256.png", cimaddr: "assets/images/amname/256.jpg" },
      { imname: "400", imaddr: "assets/images/400.png", cimaddr: "assets/images/amname/400.jpg" },
      { imname: "480", imaddr: "assets/images/480.png", cimaddr: "assets/images/amname/480.jpg" },
      { imname: "480k", imaddr: "assets/images/480k.png", cimaddr: "assets/images/amname/480k.jpg" }
    ];

  }

}
