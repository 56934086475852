import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-handjac',
  templateUrl: './handjac.component.html',
  styleUrls: ['./handjac.component.css']
})
export class HandjacComponent implements OnInit {

  hlist;
  scrtype = false;
  constructor() {
    this.imdetin()
    this.scrtype = window.innerWidth > window.innerHeight ? true : false;
  }

  ngOnInit() {
  }

  imdetin() {
    this.hlist = [
      { imname: "120", imaddr: "assets/images/amname/120.jpg",mname:'HWU120FZ' },
      { imname: "120", imaddr: "assets/images/amname/nom.jpg",mname:'HAL120FZ' },
      { imname: "172", imaddr: "assets/images/amname/172.jpg",mname:'HWU172FZ' },
      { imname: "240", imaddr: "assets/images/amname/240.jpg",mname:'HWU240FZ' },
      { imname: "240n", imaddr: "assets/images/amname/nom.jpg",mname:'HWL240FZ' },
      { imname: "256", imaddr: "assets/images/amname/256.jpg",mname:'HWU256FZ' },
      { imname: "480", imaddr: "assets/images/amname/480.jpg",mname:'HWU480HZ' },
      { imname: "480k", imaddr: "assets/images/amname/nom.jpg",mname:'HWL480HZ' }
    ];

  }
}
