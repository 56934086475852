import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FrontComponent } from './front/front.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PnfComponent } from './frames/pnf/pnf.component';
import { ToprackComponent } from './frames/toprack/toprack.component';
import { BottomrackComponent } from './frames/bottomrack/bottomrack.component';
import { HandjacComponent } from './handjac/handjac.component';
import { PowerjacComponent } from './powerjac/powerjac.component';
import { SpareComponent } from './spare/spare.component';
import { BmbarComponent } from './frames/bmbar/bmbar.component';

@NgModule({
  declarations: [
    AppComponent,
    FrontComponent,
    PnfComponent,
    ToprackComponent,
    BottomrackComponent,
    HandjacComponent,
    PowerjacComponent,
    SpareComponent,
    BmbarComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
