import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bmbar',
  templateUrl: './bmbar.component.html',
  styleUrls: ['./bmbar.component.css']
})
export class BmbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
